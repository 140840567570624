<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-select
    v-model="model.value"
    :label="model.text"
    :items="model.selection"
    item-text="selectionValue"
    item-value="selectionKey"
    multiple
    dense
    @input="$emit('input', $event)"
  >
  </v-select>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'


export default {
  name: 'HdMultipleOptionsField',
  props: {
		model: {
			type: ComponentModel,
			required: false,
			default(){
				return new ComponentModel({ value: 'Text', text: 'Default' })
			}
		}
	},
  methods: {
    translate(select) {
      return this.$transl(select.selectionValue)
    }
  }
}
</script>
