import store from '../../store/index.js'
import { COMMON_AUTH_PAGE_URL } from '../../util/URL'
export const authGuard = (to, from, next) => {
  /**
   * If we have query param "token" in the url,
   * we were redirected from "hvd common auth" and have
   * to update / set the new token.
   *
   *
   */
  //const url = new URL(window.location)
  //const url = new URL(to.fullPath)

  const tokenFromLS = localStorage.getItem('user-auth-token')
  //const tokenFromUrl = url.searchParams.get('token')

  const tokenFromUrl = to.query.token
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (!isAuthenticated) {
    if (tokenFromUrl || tokenFromLS) {
      store
        .dispatch('auth/setToken', { token: tokenFromUrl || tokenFromLS })
        .then(() => {
          return store.dispatch('init')
        })
        .then(() => {
          // removes query params
          to.query.token = undefined
          next({ path: to.path, query: to.query })
        })
    } else {
      store.dispatch('auth/logout')
    }
  } else if (isAuthenticated && tokenFromUrl) {
    window.history.pushState({}, 'redirpath', to.fullPath)
  } else {
    /**
     * When user inputs url to browser's address row
     *
     * Check what's the current company and relogin if that differs from company where they are trying to access
     */

    const userCompany = store.getters['user/getUserCompany']
    const navigateToCompany = parseInt(to.path.split('/')[2])
    if (to.path.includes('/company/') && userCompany !== navigateToCompany) {
      let currentPath = window.location.origin
      window.location.href =
        COMMON_AUTH_PAGE_URL +
        btoa(currentPath + to.fullPath) +
        '&token=' +
        store.state.auth.token +
        '&companyId=' +
        navigateToCompany
    } else {
      next()
    }
  }
}
