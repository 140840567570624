<template>
  <v-snackbar
    v-model="visible"
    style="z-index:200;"
    :timeout="2000"
    :color="color"
    elevation="24"
    bottom  
    right
    app
    :vertical="isVertical"
  >
    {{ message }}
    <v-container
      v-if="isVertical"
    >
      <v-row
        v-for="text in stackingContent"
        :key="text"
      >
        {{ text }}
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
import { EventBus } from '../util/EventBus'

export default {
  name: 'TheToast',

  data() {
    return {
       message: 'default message',
       color: 'success',
       visible: false,
       stackingContent: null,
    }
  },
  computed: {
    isVertical() {
      return this.stackingContent !== null
    },
  },
  mounted() {
    EventBus.$on('showtoast', this.showToastMessage)
  },
  methods: {
    showToastMessage(newValue) {      
      this.message = newValue.message
      this.color = newValue.type
      this.visible = true
      this.stackingContent = newValue?.stackingContent ?? null
    }
  },
}
</script>


