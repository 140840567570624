import { BaseCollection } from '../BaseCollection'
import adminApiClient from '../../api/admin-api-client'
import { User } from './User'
import { EventBus } from '../../util/EventBus'

/**
 * Class UserCollection
 *
 * @extends BaseCollection
 */

export class UserCollection extends BaseCollection {
  constructor(props) {
    super(props)
    this.showImplicitUsers = false

    this.collectionKey = 'userDbId'
  }

  /**
   * /api/Users/querybycompany
   *
   * @param {object}
   * @returns {User[]} array of users
   *
   * @override
   */
  async fetch({ companyId, subdivisionId }) {
    this.isLoading = true

    try {
      /**
       * request params are setup in setRequestOpts
       * append implicit filter here
       */
      let implicitFilter = this.showImplicitUsers ? '' : 'implicit=false'

      implicitFilter = !this.showImplicitUsers && !!this.filter ? ',' + implicitFilter : implicitFilter

      let cleanedFilter = this.filter || ''
      const licenceMatch = cleanedFilter.match(/\(.*Appid.*\)/g)

      if (cleanedFilter && licenceMatch) {
        const storeFilterString = cleanedFilter.split(licenceMatch[0])
        let newLicenceString = licenceMatch[0]
        newLicenceString = newLicenceString.replaceAll('(', '').replaceAll(')', '')
        newLicenceString = newLicenceString.replace(/\|/g, ',')
        cleanedFilter = storeFilterString[0] + newLicenceString + storeFilterString[1]
      }

      let requestParams = {
        CompanyId: companyId,
        SubdivisionId: subdivisionId,
        Page: this.page,
        PageSize: this.pageSize,
        OrderBy: this.orderBy,
        Filter: cleanedFilter + implicitFilter,
      }
      const userResponse = await adminApiClient.getCompanyUsers(requestParams, subdivisionId)

      this.count = userResponse.data.count
      this.items = userResponse.data.data.map((u) => new User(u))
      this.isLoading = false
      if (this.filter) {
        EventBus.$emit('restoreFocusEvent')
      }
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }
  /**
   *
   * @param {*} companyId
   * @param {*} page
   * @param {*} pageSize
   * @param {*} orderBy
   * @param {*} filter
   * @returns
   */
  async legacyFetch(companyId, page, pageSize, orderBy, filter) {
    console.warn('deprecated method call legacy fetch')
    this.isLoading = true
    try {
      const userResponse = await adminApiClient.getCompanyUsers({
        CompanyId: companyId,
        Page: page,
        PageSize: pageSize,
        OrderBy: orderBy,
        Filter: filter,
      })

      this.count = userResponse.data.count
      this.items = userResponse.data.data.map((u) => new User(u))
      this.isLoading = false

      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  async fetchById(userDbId) {
    this.isLoading = true
    try {
      const userResponse = await adminApiClient.getUser(userDbId)
      this.count = 1
      this.items = [new User(userResponse.data)]
      this.isLoading = false
      if (this.items.length === 1) {
        return this.items[0]
      } else {
        throw new Error('More than 1 found with id: ' + userDbId)
      }
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }
  /**
   * /api/Users/querybysubdivision
   *
   * @param {number} subdivisionId
   * @returns {User[]} array of users
   *
   */
  async fetchBySubdivision(subdivisionId, page, pageSize, orderBy, filter) {
    this.isLoading = true
    try {
      const userResponse = await adminApiClient.getCompanyUsers(
        {
          subdivisionId: subdivisionId,
          Page: page,
          PageSize: pageSize,
          OrderBy: orderBy,
          Filter: filter,
        },
        subdivisionId,
      )

      this.count = userResponse.data.count
      this.items = userResponse.data.data.map((u) => new User(u))
      this.isLoading = false

      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  /**
   * @override
   * DELETE /api/users
   *
   * @param {number} userDbId
   * @returns
   */
  delete(userDbId) {
    let user = this.items.find((u) => u.userDbId === userDbId)
    user.isLoading = true
    return adminApiClient.deleteUser(userDbId).then((response) => {
      user.isLoading = false
      if (response.status === 204) {
        let stillInArray = this.items.findIndex((u) => u.userDbId === userDbId)
        if (stillInArray > -1) {
          this.items.splice(stillInArray, 1)
        }
        this.removed = user
        return true
      }
      return false
    })
  }

  /**
   * @override
   * POST /api/users
   *
   * @param {User} user
   */
  async create(user) {
    user.authHash = undefined
    user.timestampUTC = undefined
    user.endpointId = undefined
    user.group = undefined
    user.company = undefined
    user.endpoint = undefined
    user.hasImage = undefined
    try {
      user.isLoading = true
      const response = await adminApiClient.createUser(user)
      if (response.status === 201) {
        user.userDbId = response.data
        user.isLoading = false
        this.items.push(user)
        this.added = user
        return user
      }
      throw new Error('Failed to create user!')
    } catch (ex) {
      user.isLoading = false
      this.isLoading = false
      throw ex
    }
  }

  /**
   * Connects user to subdivision
   *
   * POST /api/Users/addorupdatesubdivision
   *
   * @param {*} connectionProps
   */
  async connectUserToSubdivision(connectionProps) {
    const params = {
      userId: '' + connectionProps.user.userDbId,
      companyId: connectionProps.companyId,
      groupId: null,
      externalCompanyUser: connectionProps.externalCompanyUser,
      endpointId: connectionProps.endpointId !== 0 ? connectionProps.endpointId : null,
      subdivisionDefinition: {
        subdivisionId: connectionProps.subdivisionId,
        externalCompanyNumber: null,
        externalCompanyId: null,
      },
    }
    connectionProps.user.isLoading = true
    this.isLoading = true
    try {
      const response = await adminApiClient.connectUserToSubdivision(params)
      if (response.status === 200) {
        const index = this.items.findIndex((item) => item.userDbId == connectionProps.user.userDbId)
        if (index > -1) {
          this.updateItem(connectionProps.user, this.collectionKey)
          this.updated = connectionProps.user
        } else {
          this.items.push(connectionProps.user)
        }
        this.added = connectionProps.user
        return true
      } else {
        throw new Error('Failed to add connection')
      }
    } catch (ex) {
      this.isLoading = false
      connectionProps.user.isLoading = false
      throw ex
    }
  }
  /**
   * removes user to subdivsion connection
   *
   * POST /api/Users/removesubdivision
   * @param {*} userId
   * @param {*} subdivisionId
   * @returns
   */
  removeUserSubdivisionConnection(userDbId, companyId, subdivisionId) {
    let user = this.items.find((u) => u.userDbId === userDbId)
    if (user) {
      user.isLoading = true
    }
    return adminApiClient
      .removeUserFromSubdivision({
        userId: userDbId,
        companyId: companyId,
        SubdivisionId: subdivisionId,
        externalCompanyNumber: null,
      })
      .then((response) => {
        if (user) {
          user.isLoading = false
        }
        if (response.status === 204) {
          let stillInArray = this.items.findIndex((u) => u.userDbId === userDbId)
          if (stillInArray > -1) {
            this.items.splice(stillInArray, 1)
          }
          this.removed = user
          return true
        }
        throw new Error('Failed to remove')
      })
      .catch((ex) => {
        if (user) {
          user.isLoading = false
        }
        throw ex
      })
  }

  async queryAll(s) {
    this.isLoading = true
    try {
      const userResponse = await adminApiClient.getUsers({
        Page: 0,
        PageSize: 42,
        Filter: 'userId=*' + s + '/i|userName=*' + s + '/i',
        orderBy: 'userId asc',
      })
      this.count = userResponse.data.count
      this.items = userResponse.data.data
      this.isLoading = false

      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }
}
