<template>
  <div>
    <v-text-field
      v-model="passwordModel.password"
      :label="$t('NewPwd') + '*'"
      type="password"
      :error="!passwordModel.isValid"
      :error-messages="errorArray"
      autocomplete="new-password"
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="passwordModel.repeatPassword"
      :label="$t('NewPwdConfirm') + '*'"
      type="password"
      :error="!passwordModel.isValid"
      autocomplete="new-password-repeat"
      required
    ></v-text-field>
  </div>
</template>
<script>
import { PasswordModel } from '../../../model/password/Password'
import { clientTranslator } from '../../../i18n'

export default {
  name: 'HdPasswordFields',
  props: {
    passwordPolicy: {
      type: Number,
      required: true,
    },
    canBeEmpty: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      passwordModel: new PasswordModel({passwordPolicy: this.$store.state.user.passwordPolicies[this.passwordPolicy] }),
    }
  },
  computed: {
    errorArray(){
      return this.passwordModel.errorMessages.map((e) => {
        if(e === this.$t(e) && e.includes('[')) {
          return clientTranslator(e)
        }
        return this.$t(e)
      })
    }
  },
  watch: {
    passwordPolicy: function(newVal) {
      this.passwordModel = new PasswordModel({passwordPolicy: this.$store.state.user.passwordPolicies[newVal] })
    },
    'passwordModel.password': function(newVal) {
        this.passwordModel.dirty = !!newVal
        this.passwordModel.validate(this.canBeEmpty)
    },
    'passwordModel.repeatPassword': function() {
        this.passwordModel.validate(this.canBeEmpty)
    },
  },
  
  methods: {
    /**
     * Returns selection value
     */
    getValue() {  
      return this.passwordModel.password !== '' ? this.passwordModel.password : undefined
    },
    validate() {
      this.passwordModel.validate(this.canBeEmpty)
      return this.passwordModel.isValid
    },
    clear() {
      this.passwordModel.password = ''
      this.passwordModel.repeatPassword = ''
    }
  }
}

</script>
