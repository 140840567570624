<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <v-text-field   
      v-model="model.value"   
      :label="model.text"
      :error="!model.isValid"
      dense
      @input="updateValue($event)"
    >
    </v-text-field>
  </div>
</template>

<script>
import { ComponentModel } from '../../../model/ComponentModel'

export default {
  name: 'HdTextField',
  props: {
    model: {
      type: ComponentModel,
      required: true	
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:model', { ...this.model, value })
    }
  }
}
</script>
