import { BaseModel } from './BaseModel'
/**
 * Class ComponentModel
 *
 * Abstarction class to be used as base for models that can be
 * represented / mapped 1-to-1 as components
 *
 */
export class ComponentModel extends BaseModel {
  constructor(params) {
    super(params)

    /**
     * component name, which should be rendered in the UI
     *
     * @property {string} component
     */
    this.component = 'HdTextField'

    /**
     * array to store validation errors
     *
     * @property {array} validationErrors
     */
    this.validationErrors = []

    /**
     * @property {boolean}
     */
    this.isValid = true

    /**
     * @property {boolean} dirty true, if model has changes
     */
    this.dirty = false

    this.readOnly = false
  }
  get value() {
    return this._value || ''
  }

  set value(val) {
    this._value = val
  }

  get text() {
    return this._text || 'Default'
  }

  set text(value) {
    this._text = value
  }

  /**
   * override in subclasses
   */
  validate() {
    throw new Error('Method validate not implemented!')
  }

  /**
   * Component to be rendered
   *
   * @returns {strting}
   */
  getComponentType(typeString) {
    const typeText = typeString.toLowerCase()
    const componentTypeMap = {
      t: 'HdTextField',
      i: 'HdNumberField',
      b: 'HdTriStateField',
      lng: 'HdOptionsField',
      plug: 'HdMultipleOptionsField',
      uni: 'HdOptionsField',
      imprs: 'HdImpersonateField',
    }
    return componentTypeMap[typeText] ? componentTypeMap[typeText] : 'HdTextField'
  }
}
