<template>
  <div>
    <v-avatar
      color="primary"
      size="64"
    >
      <img
        v-if="userDbId"
        :src="imageSrc"
        alt=""
      >
    </v-avatar>
    <v-menu
      bottom
      left
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>far fa-ellipsis-v</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="onChangePicture"
        >
          <v-list-item-title>{{ $t('ChangeProfilePicture') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="onDeletePicture"
        >
          <v-list-item-title>{{ $t('DeleteProfilePicture') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <input
      ref="fileinput"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileSelected"
    >
    <HdImageCropper 
      ref="cropperdialog"
      :aspect-ratio="1"
      :img-width="125"
      :img-heigth="125"
      @imagecropped="onImageCropped"      
    />
  </div>
</template>

<script>
import { IMAGE_URL } from '../../../util/URL'
import HdImageCropper from '../../widget/HdImageCropper'
import imageApiClient from '../../../api/image-api-client'

export default {
  name: 'UserImageEdit',
  components: {
    HdImageCropper
  },
  props: {
    userDbId: {
      required: true,
      type: Number,
    }
  },
  data() {
    return {
      isLoading: false,
      deleting: false,
      imgCacheBuster: '' + Date.now(),
    }
  },
  computed: {
    imageSrc() {
      return IMAGE_URL + 'UserImage/' + this.userDbId + '?v=' + this.imgCacheBuster
    },
  },
  methods: {
    onChangePicture() {
      this.$refs.fileinput.click()
    },
    onFileSelected(event) {
       const file = event.currentTarget.files[0]
      if (file) {
    this.$refs.cropperdialog.show(file)
    this.$refs.fileinput.value = null
  }
    },
    onImageCropped(blob) {
      this.isLoading = true
      imageApiClient.setUserImage(this.userDbId, blob)
      .then((response) => {
        if(response.status === 200) {
          this.imgCacheBuster = '' + Date.now()
          this.$store.dispatch('user/setCacheBuster')
        } else {
          throw new Error(this.$t('UpdateFailed'))
        }
      })
      .catch((ex) => {
        this.$alert({ type:'error', message: ex })
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    onDeletePicture() {
      this.$confirm({
        color: 'error', 
        title: this.$t('DeleteProfilePicture'), 
        text: this.$t('ConfirmDeleteProfilePicture'),
        confirmButtonText: this.$t('Delete'),        
      })
      .then(() => {
        this.deleting = true
        return imageApiClient.deleteUserImage(this.userDbId)      
      })
      .then((response)=>{
          if(response.status === 200 || response.status === 204) {
            //this.$toast({type: 'success', message: this.$t('Deleted')} )
            this.imgCacheBuster = '' + Date.now()
            this.$store.dispatch('user/setCacheBuster')
          } else {
            throw new Error(this.$t('UpdateFailed'))
          }
      })
      .catch((ex) => {          
        if(ex !== 'cancel') {
          // api error  
          this.$alert({type: 'warning', message: this.$handleError(ex) })
        }
      })
      .finally(() => {
        this.deleting = false
      })
    }
  }
}
</script>
