<template>
  <div>
    <div class="mt-5 mb-2">
      {{ $t('Signature') }}
    </div>
    <p v-if="isLoading">
      {{ $t('Loading') }}
    </p>
    <v-btn
      v-if="notCreated"
      color="success"
      :disabled="isCreating"
      :loading="isCreating"
      @click="addSignature"
    >
      {{ $t('AddSignature') }}
    </v-btn>
    <div v-if="signature">
      <HdRichTextEditor
        v-model="markup"
        :placeholder-text="$t('Signature')"
        :disabled="isUpdating || isDeleting"
      />
      <v-btn
        color="error"
        class="mt-2"
        :disabled="isDeleting"
        :loading="isDeleting"
        @click="removeSignature"
      >
        {{ $t('DeleteSignature') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import emailApiClient from '../../../api/email-api-client'
import HdRichTextEditor from '../field/HdRichTextEditor.vue'
export default {
  name: 'MailSignatureEditor',
  components: { HdRichTextEditor },
  props: {
    externalId: {
      required: true,
      type: Number,
    },
    // Available values : CompanyId, SubdivisionId, UserDbId
    type: {
      required: true,
      type: String,
    },
  },
  emits: ['change'],
  data() {
    return {
      isLoading: true,
      isCreating: false,
      isDeleting: false,
      isUpdating: false,
      notCreated: false,
      signature: null,
      markup: '',
    }
  },
  mounted() {
    emailApiClient
      .getSignature(this.externalId, this.type)
      .then((res) => {
        this.signature = res.data
        // Add empty content for the font size and family selections to be active
        // Loses the placeholder
        this.signature.data =
          this.signature.data ||
          '<p style="line-height: 1;"><span style="font-size: 10pt; font-family: Arial;"><span class="ql-cursor">&#xFEFF;</span></span></p>'
        this.markup = this.signature.data || ''
      })
      .catch((ex) => {
        const res = ex.response
        if (res && res.data.status === 404 && res.data.detail === 'Signature does not exist') {
          this.notCreated = true
        } else {
          this.$alert({ type: 'error', message: this.$handleError(ex) })
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    addSignature() {
      const payload = {
        signatureIdType: this.type,
        externalId: this.externalId,
        data: '',
      }
      this.isCreating = true
      emailApiClient
        .createSignature(payload)
        .then((res) => {
          this.signature = res.data
          this.signature.data = this.signature.data || ''
          this.markup = this.signature.data || ''
          this.notCreated = false
        })
        .catch((ex) => {
          this.$alert({ type: 'error', message: this.$handleError(ex) })
        })
        .finally(() => {
          this.isCreating = false
        })
    },
    removeSignature() {
      this.$confirm({
        color: 'error',
        title: this.$t('Delete'),
        text: this.$t('ConfirmDeleteSignature'),
        confirmButtonText: this.$t('Delete'),
      })
        .then(() => {
          this.isDeleting = true
          return emailApiClient.deleteSignature(this.signature.signatureId)
        })
        .then(() => {
          this.signature = null
          this.markup = ''
          this.notCreated = true
        })
        .catch((ex) => {
          if (ex !== 'cancel') {
            this.$alert({ type: 'error', message: this.$handleError(ex) })
          }
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    /**
     * Only update when there is a signature and it has changes
     * Call from the outside when the form containing this component saves
     */
    updateSignature() {
      if (!this.hasChanges()) {
        return Promise.resolve()
      }
      this.isUpdating = true
      const copy = {
        ...this.signature,
        data: this.markup,
      }
      return emailApiClient
        .updateSignature(copy)
        .then((res) => {
          this.isUpdating = false
          this.signature = res.data
          this.markup = res.data.data
        })
        .catch((ex) => {
          this.isUpdating = false
          throw ex
        })
    },
    hasChanges() {
      return this.signature && this.signature.data !== this.markup
    },
    getValue() {
      return this.markup
    },
  },
}
</script>
