<template>
  <v-autocomplete
    ref="companyselect"
    :items="cc.items"
    :loading="cc.isLoading"
    dense
    prepend-inner-icon="far fa-search"
    loader-height="4"
    item-text="companyName"    
    item-value="companyId"
    :label="$t('Search') + ' ' + $t('Company').toLowerCase()"
    class="hd-autocomplete"
    clearable
    :rules="required ? [v => !!v || ' ' ]: []"
    @keyup="onSearch"
    @click:clear="initLoad"
    @change="onChange"
  >
    <template #item="{item}">
      <div class="ml-2">
        {{ item.companyName }} ({{ item.companyId }})
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { CompanyCollection } from '../../../model/company/CompanyCollection'

export default {
  name: 'HdCompanySearch',
  components: {
    
  },
  props: {
    required: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    }
  },

  data() {
    return {
      cc: new CompanyCollection({ pageSize: 100 }),
      searchTimeout: null
    }
  },

  mounted() {
    this.initLoad()
  },
  methods: {
    onSearch() {
      
      clearTimeout(this.searchTimeout)

      this.cc.items = []
      let _this = this
      
      this.searchTimeout = setTimeout(function() {    
        let search = _this.$refs.companyselect.internalSearch

        if (_this.cc.isLoading) {
          return
        }
        _this.cc.items = []
        _this.cc.filter = search ? `companyName=*${search}/i` : null
        _this.cc.fetch()
      }, 500)      
    },
    initLoad() {
      this.cc.filter = null
    },

    onChange(e) {
      let company = this.cc.getRecordByCollectionKey(e)
      this.$emit('companyselected', company)
    },
    /**
     * 
     * @returns {User} selected user 
     */
    getValue() {      
      return this.$refs.companyselect.selectedItems[0]
    },

    reset() { 
      return this.$refs.companyselect.reset()
    },
    
  },
}
</script>