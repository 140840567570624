export const propertyType = {
  company: 3,
  user: 1,
  endpoint: 2,
}

export const groupValue = {
  PortalSuperAdmin: 1000,
  HostingAdmin: 800,
  PortalGlobalAdmin: 500,
  PartnerAdmin: 400,
  PortalUserAdmin: 100,
  CompanyToken: 100,
  SubdivisionAdmin: 90,
  PortalUser: 50,
  PortalExternalUser: 20,
  PortalGuest: 10,
  UnknownUser: 0,
}

export const groupId = {
  PortalSuperAdmin: 5,
  HostingAdmin: 6,
  PortalGlobalAdmin: 2,
  PartnerAdmin: 11,
  PortalUserAdmin: 1,
  CompanyToken: 7,
  SubdivisionAdmin: 10,
  PortalUser: 3,
  PortalExternalUser: 9,
  PortalGuest: 8,
  UnknownUser: 4,
}

export const logoType = {
  companyLogo: 1,
  industryLogo: 2,
}

export const module = {
  test: 'test',
  advanced: 'advanced',
  translator: 'translator',
}
