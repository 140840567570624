import { BaseModel } from '../BaseModel'
import { Endpoint } from '../endpoint/Endpoint'
import adminApiClient from './../../api/admin-api-client'
/**
 * Class Subdivision
 *
 * @extends BaseModel
 */
export class Subdivision extends BaseModel {
  constructor(props) {
    super(props)
    this.subdivisionId = props.subdivisionId || 0
    this.subdivisionName = props.subdivisionName || ''
    this.externalCompanyNumber = props.externalCompanyNumber || null
    this.externalCompanyId = props.externalCompanyId || ''
    this.endpointId = props.endpointId || null
    this.endpointName = props.endpointName || null
    this.endpoint = props.endpoint ? new Endpoint(props.endpoint) : new Endpoint({})
    this.userCount = props.userCount || null
    // these are not set by api, done manually
    this.implicit = props.implicit || false
    this.companyId = props.companyId || 0
    this.allowPersonalMail = Boolean(props.allowPersonalMail) || false
    this.useSubdivisonMailAccount = Boolean(props.useSubdivisonMailAccount) || false
    this.locale = props.locale || ''
    this.fiscalId = props.fiscalId || ''
    /**
     * Save the initial state of the object for comparing changes
     */
    this._initialValues = { ...this, _initialValues: null }
  }

  get text() {
    return this.subdivisionName
  }

  get id() {
    return this.subdivisionId
  }
  /**
   *
   */
  async update() {
    if (!this.companyId) {
      throw new Error('CompanyId is not set on subdivision')
    }

    const data = {
      companyId: this.companyId,
      data: {
        subdivisionId: this.subdivisionId,
        subdivisionName: this.subdivisionName,
        externalCompanyId: this.externalCompanyId,
        externalCompanyNumber: this.externalCompanyNumber,
        endpointId: this.endpointId,
        allowPersonalMail: this.allowPersonalMail ? 1 : 0,
        useSubdivisonMailAccount: this.useSubdivisonMailAccount ? 1 : 0,
        fiscalId: this.fiscalId,
        locale: this.locale,
      },
    }
    this.isLoading = true
    return adminApiClient
      .updateSubdivision(this.companyId, this.subdivisionId, data)
      .then((response) => {
        if (response.status === 200) {
          this.isLoading = false
          return true
        }
        throw new Error('Failed to save!')
      })
      .catch((ex) => {
        this.isLoading = false
        throw ex
      })
  }

  transformForComparison(model) {
    model.externalCompanyId = model.externalCompanyId || ''
    model.externalCompanyNumber = model.externalCompanyNumber || null
    return model
  }
}
