<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="30%"
    style="z-index: 9999"
  >
    <v-card>
      <v-card-title>
        <v-icon          
          size="16"
          class="mx-2"
        >
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn          
          text
          @click="handleClick(false)"  
        >
          {{ cancelButtonText }}
        </v-btn>
        <v-btn
          :color="color"
          elevation="0"
          @click="handleClick(true)"
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '../util/EventBus'

export default {
  name: 'HdConfirmModal',
  props: {
    shared: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      color: 'primary',
      text: 'Sure?',
      icon: 'fas fa-exclamation-circle',
      title: this.$t('Confirm'),
      confirmButtonText: this.$t('Confirm'),
      cancelButtonText: this.$t('Cancel'),
      visible: false,

      resolveFnc: undefined,
      rejectFnc: undefined,
    }
  },
  mounted() {
    if (this.shared) {
      EventBus.$on('showconfirm', this.show)
    }
  },
  methods: {
    handleClick(b) {      
      this.visible = false
      if (this.shared) {
        EventBus.$emit('handleconfirm', b || 'cancel')
      } else if(b) {
          this.resolveFnc(b)
        }else {
          this.rejectFnc('cancel')
        }
    },

    /**
     * @param {object} opts
     * 
     * @returns {Promise}
     */
    show(opts = {}) {
      this.color = opts.color || this.color
      this.title = opts.title || this.title
      this.confirmButtonText = opts.confirmButtonText || this.confirmButtonText
      this.cancelButtonText = opts.cancelButtonText || this.cancelButtonText
      this.text = opts.text || this.text
      this.visible = true

      if (!this.shared) {
        return new Promise((resolve, reject) => {
          this.resolveFnc = resolve
          this.rejectFnc = reject
        })
      }
    }
  }
}
</script>
