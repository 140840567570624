<template>
  <div
    v-if="$accesscontrol().isGlobalAdmin"
  >
    <v-btn
      v-show="!searchActive"
      icon
      @click="activateSearch"
    >
      <v-icon
        small
      >
        fas fa-search
      </v-icon>
    </v-btn>
    <v-autocomplete
      v-show="searchActive"
      ref="gusersearch"
      :items="uc.items"
      :loading="uc.isLoading"
      dense
      flat
      clearable
      prepend-inner-icon="far fa-search"
      item-text="userId"
      class="hd-autocomplete hd-global-search"
      return-object
      :filter="(i) => i"
      @blur="searchActive = false"
      @keyup="onSearch"
      @change="onChange"
    >
      <template #item="{item}">    
        <div class="ml-2 mb-1">
          <div class="text-caption">
            {{ item.userId }} ({{ item.userName }})
          </div>
          <div class="font-weight-thin">
            {{ item.companyId }} {{ item.companyName }}
          </div>
        </div>
      </template> 
    </v-autocomplete>
  </div>
</template>
<script>
import { UserCollection } from '../../model/user/UserCollection'

export default {
  name: 'HdGlobalUserSearch',

  components: {
  },
  data() {
    return {
      uc: new UserCollection({}),
      searchTimeout: null,
      searchActive: false
    }
  },
  methods: {
    onSearch() {
      clearTimeout(this.searchTimeout)

      this.uc.items = []
      let _this = this
      
      this.searchTimeout = setTimeout(function() {    
        let search = _this.$refs.gusersearch.internalSearch

        if (_this.uc.isLoading) {
          return
        }
        _this.uc.items = []
        _this.uc.queryAll(search)
      }, 500)      
    },
    onChange(e) {
      this.uc.items = []
      this.searchActive = false
      this.$router.push('/company/' + e.companyId + '/users')
    
    },
    activateSearch() {
      this.searchActive = true
      setTimeout(() => {
        this.$refs.gusersearch.$refs.input.focus()
      }, 10)
      
    }
  },
  
  
}

</script>
<style scoped>
.hd-global-search {
  width: 400px !important;
  margin-top: 20px !important;
}
</style>