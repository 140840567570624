<template>
  <HdDialog
    v-model="visible"
  >
    <v-card-title class="text-h5">
      {{ user.name }}
    </v-card-title>

    <HdCompanySearch 
      @companyselected="onCompanySelected"
    />
     
    <HdSubdivisionSelector 
      ref="subdiv"
      :company-id="companyId"
    />

    <v-text-field
      ref="systemuser"
      :label="$t('AdminExtEntreUser')"
    >
    </v-text-field>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="visible = false"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        :loading="isLoading"
        depressed
        @click="onMoveClick"
      >
        {{ $t('Move') }}
      </v-btn>
    </v-card-actions>
  </HdDialog>
</template>
<script>
import { User } from '../../../model/user/User'
import HdDialog from '../../HdDialog'
import HdCompanySearch from '../field/HdCompanySearch'
import HdSubdivisionSelector from '../field/HdSubdivisionSelector'

export default {
  name: 'ChangeUserCompanyDialog',
  components: { 
    HdDialog,
    HdCompanySearch,
    HdSubdivisionSelector 
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    value: {  // v-model binding for dialog, refactor using v-model:dialog
      type: Boolean
    }
  },
  data() {
    return {
      dialogVisible: false,
      companyId: 0,
      isLoading: false
    }
  },
  computed: {
    visible: { // allow v-model for dialog
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onMoveClick() {

      if(this.companyId && this.user.userDbId) {
        this.isLoading = true
        
        const obj = {
          userDbId: this.user.userDbId,
          toCompanyId: this.companyId,
          toSubdivisionIds: [ this.$refs.subdiv.getValue() ],
          externalCompanyUser: this.$refs.systemuser.internalValue
        }

        this.user.changeUserCompany(obj)
        .then(() => {
          this.visible = false
          this.$emit('usermoved', this.user)
          this.$toast({type: 'success', message: 'User moved to companyId: ' + this.companyId })

        })
        .catch(ex => {
          this.$alert({ message: this.$handleError(ex), type: 'error'})

        })
        .finally(() => this.isLoading = false)
      }
    },
    onCompanySelected(company) {
    
      this.companyId = company.companyId
      this.$refs.subdiv.fetchWithId(this.companyId)
    }
  }
}
</script>
