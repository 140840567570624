import { BaseModel } from '../BaseModel'
import adminApiClient from '../../api/admin-api-client'
import { Property } from '../property/Property'
/**
 * Class Company
 *
 * @extends BaseModel
 */
export class Company extends BaseModel {
  constructor(params) {
    super(params)

    this.companyId = params.companyId || 0
    this.companyName = params.companyName || ''
    this.customerNumber = params.customerNumber || null
    this.active = params.active != undefined ? params.active : true
    this.passwordPolicyId = params.passwordPolicyId || 0
    this.countryCode = params.countryCode || null
    this.companyCategoryId = params.companyCategoryId || 1 // none
    this.createdByCompanyName = params.createdByCompanyName || ''
    this.createdByCompanyId = params.createdByCompanyId || null
    this.properties = params.properties ? params.properties.map((p) => new Property(p)) : []
    this.subdivisions = params.subdivisions || []
    this.invoiceCustomerNumber = params.invoiceCustomerNumber || null
    this.allowPersonalMail = Boolean(params.allowPersonalMail) || false
    this.locale = params.locale || null
    this.fiscalId = params.fiscalId || ''

    this._initialValues = { ...this, _initialValues: null }
  }

  /**
   * @override
   */
  update() {
    const companyToBeSaved = { ...this }
    companyToBeSaved._initialValues = undefined
    companyToBeSaved.allowPersonalMail = this.allowPersonalMail ? 1 : 0
    companyToBeSaved.subdivisions = null //why though?
    this.isLoading = true
    return adminApiClient
      .updateCompany(companyToBeSaved.companyId, { data: companyToBeSaved })
      .then((response) => {
        if (response.status === 200) {
          return true
        }
        throw new Error('Failed')
      })
      .catch((ex) => {
        this.resetInitialValues()
        throw ex
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  transformForComparison(model) {
    const onlyValues = (props) => {
      return props
        .map((prop) => {
          return {
            propertyValue: prop.propertyValue ? prop.propertyValue.toString().toLowerCase() : prop.propertyValue,
          }
        })
        .filter((prop) => !!prop.propertyValue)
    }
    model.customerNumber = model.customerNumber || null
    model.properties = onlyValues(model.properties)
    return model
  }
  async refresh() {
    this.isLoading = true
    try {
      const licenseResponse = await adminApiClient.getCompany({
        companyId: this.companyId,
      })
      const newData = licenseResponse.data
      this.companyId = newData.companyId
      this.companyName = newData.companyName
      this.customerNumber = newData.customerNumber
      this.active = newData.active
      this.passwordPolicyId = newData.passwordPolicyId
      this.countryCode = newData.countryCode
      this.companyCategoryId = newData.companyCategoryId
      this.createdByCompanyName = newData.createdByCompanyName
      this.createdByCompanyId = newData.createdByCompanyId
      this.subdivisions = newData.subdivisions
      this.invoiceCustomerNumber = newData.invoiceCustomerNumber
      this.locale = newData.locale
      this.fiscalId = newData.fiscalId
      this.isLoading = false
      return this
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }
}
