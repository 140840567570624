<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-switch
    v-model="model.value"
    :label="model.text"
    :disabled="model.readOnly"    
    dense
    class="hd-checkbox"
    @input="updateValue"
  >
  </v-switch>
</template>
<script>
import { ComponentModel } from '../../../model/ComponentModel'

export default {
  name: 'HdToggleField',
  props: {
		model: {
			type: ComponentModel,
			required: false,
      default() {
        return new ComponentModel()
      }			
		}
	},

  methods: {
    updateValue(newValue) {
      const updatedModel = { ...this.model, value: newValue }
      this.$emit('update:model', updatedModel)
      this.$emit('valuechange', updatedModel)
    }
  },

}
</script>

<style scoped>
.hd-checkbox {
  padding-top: 0;
  color: #fff;
   margin-top: 0;
}
</style>
