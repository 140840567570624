import { accessGuard } from './guards/access-guard'
import { groupValue, module } from '../util/enum'

const Error404Page = () => import('../views/Error404Page.vue')
const ApplicationListPage = () => import('../views/ApplicationListPage.vue')
const TranslationsPage = () => import('../views/TranslationsPage.vue')
const RssPage = () => import('../views/RssPage.vue')
const TilesPage = () => import('../views/TilesPage.vue')
const CompanyPage = () => import('../views/company/CompanyPage.vue')
const CompanyGridPage = () => import('../views/company/CompanyGridPage.vue')
//company children
const CompanyUserGridPage = () => import('../views/company/CompanyUserGridPage.vue')
const CompanyEndpointGridPage = () => import('../views/company/CompanyEndpointGridPage.vue')
const CompanyOperationsPage = () => import('../views/company/CompanyOperationsPage.vue')
const CompanySubdivisionGridPage = () => import('../views/company/CompanySubdivisionGridPage.vue')
const CompanyPropertiesPage = () => import('../views/company/CompanyPropertiesPage.vue')
const CompanySubdivisionPage = () => import('../views/company/CompanySubdivisionPage.vue')
// DMS related
const DMSQuestionnairesPage = () => import('../views/dms/DMSQuestionnairesPage.vue')
const DMSQuestionnairePage = () => import('../views/dms/DMSQuestionnairePage.vue')
const DMSProcessQuestionnairePage = () => import('../views/dms/DMSProcessQuestionnairePage.vue')
const DMSFileContainersPage = () => import('../views/dms/DMSFileContainersPage.vue')
const DMSFileContainerPage = () => import('../views/dms/DMSFileContainerPage.vue')
const DMSFileLabelsPage = () => import('../views/dms/DMSFileLabelsPage.vue')
const DMSTenantConfigPage = () => import('../views/dms/DMSTenantConfigPage.vue')
//Licenses
const LicensesMainPage = () => import('../views/licenses/LicensesMainPage.vue')
const LicensesPage = () => import('../views/licenses/LicensesPage.vue')
const FeaturesPage = () => import('../views/licenses/FeaturesPage.vue')
const InspectLicense = () => import('../views/licenses/InspectLicense.vue')
const LicensesGroupPage = () => import('../views/licenses/LicensesGroupPage.vue')
//Config
const ConfigsPage = () => import('../views/config/ConfigsPage.vue')
const ConfigGridPage = () => import('../views/config/ConfigGridPage.vue')
const ConfigGroupGridPage = () => import('../views/config/ConfigGroupGridPage.vue')
const ConfigTypeGridPage = () => import('../views/config/ConfigTypeGridPage.vue')
const ConfigCriteriaGridPage = () => import('../views/config/ConfigCriteriaGridPage.vue')
//Hosting
const HostingMainPage = () => import('../views/hosting/HostingMainPage.vue')
const HostingCommandsPage = () => import('../views/hosting/HostingCommandsPage.vue')
const HostingIconsPage = () => import('../views/hosting/HostingIconsPage.vue')
//Stats
const StatisticLicensePage = () => import('../views/statistic/StatisticLicensesGridPage.vue')
const StatisticGuestPage = () => import('../views/statistic/StatisticGuestGridPage.vue')
const StatisticTickPage = () => import('../views/statistic/StatisticTickGridPage.vue')
//AuditLog
const AuditLogPage = () => import('../views/AuditLogsPage.vue')

const ProductSetupPage = () => import('../views/productsetup/ProductSetupGridPage.vue')

const routes = [
  {
    path: '/',
    alias: '/companies',
    name: 'Companies',
    component: CompanyGridPage,
    meta: {
      requiredAccessLevel: 0,
      addToMenu: true,
      icon: 'far fa-building',
      translationKey: 'Companies',
    },
  },
  {
    path: '/logs',
    name: 'Logs',
    beforeEnter: accessGuard,
    component: AuditLogPage,
    meta: {
      requiredAccessLevel: groupValue.PortalSuperAdmin,
      addToMenu: false,
      icon: 'far fa-building',
      translationKey: 'Log',
    },
  },
  {
    path: '/productsetups',
    name: 'ProductSetups',
    beforeEnter: accessGuard,
    component: ProductSetupPage,
    meta: {
      requiredAccessLevel: groupValue.HostingAdmin,
      addToMenu: false,
      icon: 'far fa-building',
      translationKey: 'ProductSetup',
    },
  },

  {
    path: '/licenses',
    name: 'LicensesMain',
    component: LicensesMainPage,
    props: true,
    beforeEnter: accessGuard,
    redirect: '/licenses/licenseslist',
    meta: {
      requiredAccessLevel: groupValue.PortalSuperAdmin,
      addToMenu: true,
      icon: 'far fa-id-card',
      translationKey: 'Licenses',
    },
    children: [
      {
        path: 'licensegroupslist',
        name: 'LicenseGroups',
        component: LicensesGroupPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          translationKey: 'LicenseGroups',
          icon: 'fal fa-layer-group',
        },
      },
      {
        path: 'licenseslist',
        name: 'Licenses',
        component: LicensesPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          translationKey: 'Licenses',
          icon: 'fal fa-id-card',
        },
      },
      {
        path: 'featureslist',
        component: FeaturesPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          translationKey: 'Features',
          icon: 'fal fa-toolbox',
        },
      },
      {
        path: '/licenses/:id/inspect',
        name: 'Licenses',
        component: InspectLicense,
        beforeEnter: accessGuard,
        props: true,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
        },
      },
    ],
  },
  {
    path: '/applications',
    name: 'Applications',
    component: ApplicationListPage,
    props: true,
    beforeEnter: accessGuard,
    meta: {
      requiredAccessLevel: groupValue.PortalGlobalAdmin,
      addToMenu: false,
      icon: 'far fa-clouds',
      translationKey: 'Applications',
    },
  },
  {
    path: '/translations',
    name: 'Translations',
    component: TranslationsPage,
    beforeEnter: accessGuard,
    meta: {
      requiredAccessLevel: groupValue.PortalGlobalAdmin,
      requiredModules: [module.translator],
      addToMenu: true,
      icon: 'far fa-globe-americas',
      translationKey: 'Translations',
    },
  },
  {
    path: '/rss',
    name: 'Rss',
    component: RssPage,
    beforeEnter: accessGuard,
    meta: {
      requiredAccessLevel: groupValue.PortalGlobalAdmin,
      requiredModules: [module.test],
      addToMenu: true,
      icon: 'far fa-rss',
      translationKey: 'Rss',
    },
  },
  {
    path: '/tiles',
    name: 'Tiles',
    component: TilesPage,
    beforeEnter: accessGuard,
    meta: {
      requiredAccessLevel: groupValue.PortalGlobalAdmin,
      addToMenu: true,
      icon: 'far fa-th-large',
      translationKey: 'Tiles',
    },
  },
  {
    path: '/configs',
    name: 'Configs',
    redirect: '/configs/list',
    component: ConfigsPage,
    beforeEnter: accessGuard,
    meta: {
      requiredAccessLevel: groupValue.PortalGlobalAdmin,
      addToMenu: true,
      icon: 'far fa-cog',
      translationKey: 'Configs',
    },
    props: true,
    children: [
      {
        path: 'list',
        component: ConfigGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Configs',
          icon: 'fal fa-cog',
        },
      },
      {
        path: 'groups',
        component: ConfigGroupGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Groups',
          icon: 'fal fa-layer-group',
        },
      },
      {
        path: 'types',
        component: ConfigTypeGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Types',
          icon: 'fal fa-list',
        },
      },
      {
        path: 'criteria',
        component: ConfigCriteriaGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Criteria',
          icon: 'fal fa-filter',
        },
      },
    ],
  },
  {
    path: '/hosting',
    name: 'HostingMain',
    component: HostingMainPage,
    props: true,
    beforeEnter: accessGuard,
    redirect: '/hosting/commandslist',
    meta: {
      requiredAccessLevel: groupValue.PortalSuperAdmin,
      addToMenu: true,
      icon: 'far fa-server',
      translationKey: 'Hosting',
    },
    children: [
      {
        path: 'commandslist',
        name: 'Commands',
        component: HostingCommandsPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          translationKey: 'Commands',
          icon: 'fal fa-code',
        },
      },
      {
        path: 'iconlist',
        name: 'Icons',
        component: HostingIconsPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          translationKey: 'Icons',
          icon: 'fal fa-camera',
        },
      },
    ],
  },
  {
    path: '/company/:id',
    name: 'Company',
    component: CompanyPage,
    redirect: '/company/:id/properties',
    props: true,
    children: [
      {
        path: 'properties',
        component: CompanyPropertiesPage,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'Properties',
          icon: 'fal fa-cog',
        },
      },
      {
        path: 'users',
        component: CompanyUserGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'Users',
          icon: 'fal fa-users',
        },
      },
      {
        path: 'endpoints',
        beforeEnter: accessGuard,
        component: CompanyEndpointGridPage,
        meta: {
          requiredAccessLevel: groupValue.PartnerAdmin,
          addToMenu: true,
          translationKey: 'Endpoints',
          icon: 'fal fa-globe',
        },
      },
      {
        path: 'subdivisions',
        component: CompanySubdivisionGridPage,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'Subdivisions',
          icon: 'fal fa-project-diagram',
        },
      },
      {
        path: 'subdivision/:sid',
        component: CompanySubdivisionPage,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: false,
          translationKey: 'Subdivisions',
        },
      },
      {
        path: 'shortcuts',
        beforeEnter: accessGuard,
        component: CompanyOperationsPage,
        meta: {
          requiredAccessLevel: groupValue.PartnerAdmin,
          addToMenu: false, //Modified in CompanyPage.vue, if feature is active
          translationKey: 'Shortcuts',
          icon: 'fal fa-server',
        },
      },
      {
        path: 'questionnaires',
        component: DMSQuestionnairesPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'DMSQuestionnaires',
          icon: 'fal fa-question-circle',
          subHeaderTranslationKey: 'DocumentManagement', //Use to add subheader to secondary menu
        },
      },
      {
        path: 'tenantconfig',
        component: DMSTenantConfigPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'TenantConfig',
          icon: 'fal fa-list',
        },
      },
      {
        path: 'questionnaire/:qid',
        component: DMSQuestionnairePage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: false,
          translationKey: 'DMSQuestionnaires',
        },
      },
      {
        path: 'questionnaire/:qid/process',
        component: DMSProcessQuestionnairePage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: false,
          translationKey: 'DMSQuestionnaires',
        },
      },
      {
        path: 'filecontainers',
        component: DMSFileContainersPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'DMSFileContainers',
          icon: 'fal fa-folders',
        },
      },
      {
        path: 'filecontainer/:cid',
        component: DMSFileContainerPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: false,
          translationKey: 'DMSFileContainer',
        },
      },
      {
        path: 'filelabels',
        component: DMSFileLabelsPage,
        beforeEnter: accessGuard,
        meta: {
          requiredAccessLevel: groupValue.PortalUserAdmin,
          addToMenu: true,
          translationKey: 'DMSFileLabels',
          icon: 'fal fa-tag',
        },
      },
      {
        path: 'guest',
        component: StatisticGuestPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Guest',
          icon: 'fal fa-list',
          subHeaderTranslationKey: 'CompanyLogs', //Use to add subheader to secondary menu
        },
      },
      {
        path: 'tick',
        component: StatisticTickPage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'Tick',
          props: true,
          icon: 'fal fa-list',
        },
      },
      {
        path: 'license',
        component: StatisticLicensePage,
        meta: {
          requiredAccessLevel: groupValue.PortalGlobalAdmin,
          addToMenu: true,
          translationKey: 'License',
          icon: 'fal fa-list',
        },
      },
    ],
  },
  {
    path: '/',
    name: 'OverflowMenu',
    children: [
      {
        path: '/logs',
        name: 'Logs',
        beforeEnter: accessGuard,
        component: AuditLogPage,
        meta: {
          requiredAccessLevel: groupValue.PortalSuperAdmin,
          addToMenu: true,
          icon: 'far fa-building',
          translationKey: 'Log',
        },
      },
      {
        path: '/productsetups',
        name: 'ProductSetups',
        beforeEnter: accessGuard,
        component: ProductSetupPage,
        meta: {
          requiredAccessLevel: groupValue.HostingAdmin,
          addToMenu: true,
          icon: 'far fa-building',
          translationKey: 'ProductSetup',
        },
      },
    ],
  },
  {
    // Must be last, catch all
    path: '/*',
    name: 'Error404Page',
    component: Error404Page,
  },
]

export default routes
