<template>
  <div>
    <CreateEditUserPanel
      v-if="impersonateAccount"
      ref="editimpersonateaccount"
      stand-alone
    />
    <v-app-bar
      id="the-app-bar"
      app
      flat
      elevation="1"
      color="navigationbackground"
      dark
      clipped-left
      dense
      class="top-navigation"
    >
      <v-img
        max-width="30"
        class="ml-3 mr-2"
        :src="require(`../assets/img/logo.svg`)"
      >
      </v-img>
      <div
        class="ml-2 mr-6"
        style="font-size: 0.9rem"
      >
        Portal Admin
      </div>
      <v-btn
        v-for="item in menuItems"
        :key="item.id"
        :to="item.route"
        text
        tile
        height="100%"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ item.icon }}
        </v-icon>
        {{ item.text }}
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in overflowMenuItems"
            :key="item.id"
            :to="item.route"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>

      <v-fade-transition :hide-on-leave="true">
        <HdGlobalUserSearch />
      </v-fade-transition>
      <v-menu :nudge-width="200">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            depressed
            text
            icon
            class="mr-3"
            v-on="on"
          >
            <v-avatar
              size="30"
              class="mx-2"
            >
              <img
                :src="avatar"
                alt=""
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="mb-4">
              <v-list-item-content>
                <v-list-item-title> {{ user.userName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.userId }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ user.company.companyName }} ({{ user.company.companyId }})
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              v-if="userOriginalCompany"
              @click="openMyCompany"
            >
              <v-list-item-icon>
                <v-icon small>
                  far fa-building
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ userOriginalCompany.companyName }} ({{ userOriginalCompany.companyId }})
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('MyCompany') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="impersonateAccount"
              @click="
                $refs.editimpersonateaccount.show(
                  impersonateAccount.userDbId,
                  impersonateAccount.companyId,
                  impersonateAccount.passwordPolicyId,
                )
              "
            >
              <v-list-item-icon>
                <v-icon small>
                  fas fa-ghost
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ impersonateAccount.userId }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('ImpersonateAccount') }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="toggleTheme(!$vuetify.theme.dark)">
              <v-list-item-icon>
                <v-icon small>
                  {{ isDarkTheme ? 'fas fa-sun' : 'fas fa-moon' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ isDarkTheme ? $t('UseLightTheme') : $t('UseDarkTheme') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon small>
                  far fa-sign-out
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Logout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import routes from '../router/routes'
import HdGlobalUserSearch from '../components/widget/HdGlobalUserSearch.vue'
import { COMMON_AUTH_PAGE_URL } from '../util/URL'
import CreateEditUserPanel from '../components/form/user/CreateEditUserPanel'

export default {
  name: 'TheAppBar',
  components: {
    HdGlobalUserSearch,
    CreateEditUserPanel,
  },
  data() {
    return {
      currentPageTitle: '',
      title: 'Portal Admin',
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/getAvatar',
      loggedInUserLevel: 'user/getUserGroupLevel',
      isDarkTheme: 'ui/isDarkTheme',
      userCompany: 'user/getUserCompany',
    }),
    ...mapState({
      isLoading: 'isLoading',
    }),

    ...mapState('user', {
      user: (state) => state.tokenInfo,
      userOriginalCompany: (state) => state.userOriginalCompany,
      impersonateAccount: (state) => state.impersonateAccount,
      hideCompanyListLink: (state) => state.hideCompanyListLink,
    }),

    menuItems() {
      const self = this
      let items = routes.filter((r) => {
        let hasRequiredAccessLevel = r.meta?.addToMenu && this.loggedInUserLevel >= r.meta.requiredAccessLevel
        // if the array is empty no module is required -> return true
        let hasRequiredModules = r.meta?.requiredModules
          ? r.meta.requiredModules.every((module) => self.$store.getters['user/hasModule'](module))
          : true
        let hasRequiredProperty = r.meta?.requiredProperty
          ? self.$store.getters['user/hasProperty'](r.meta.requiredProperty)
          : true
        return (
          hasRequiredAccessLevel &&
          hasRequiredModules &&
          hasRequiredProperty &&
          (r.name !== 'Companies' || !this.hideCompanyListLink)
        )
      })

      //
      return items.map((item) => ({
        route: item.path,
        text: this.$t(item.meta.translationKey),
        icon: item.meta.icon,
      }))
    },
    overflowMenuItems() {
      const self = this
      let items = routes.find((r) => {
        return r.name == 'OverflowMenu'
      })
      let itemschildren = items.children.filter((r) => {
        let hasRequiredAccessLevel = r.meta?.addToMenu && this.loggedInUserLevel >= r.meta.requiredAccessLevel
        // if the array is empty no module is required -> return true
        let hasRequiredModules = r.meta?.requiredModules
          ? r.meta.requiredModules.every((module) => self.$store.getters['user/hasModule'](module))
          : true
        let hasRequiredProperty = r.meta?.requiredProperty
          ? self.$store.getters['user/hasProperty'](r.meta.requiredProperty)
          : true
        return (
          hasRequiredAccessLevel &&
          hasRequiredModules &&
          hasRequiredProperty &&
          (r.name !== 'Companies' || !this.hideCompanyListLink)
        )
      })
      return itemschildren.map((item) => ({
        route: item.path,
        text: this.$t(item.meta.translationKey),
        icon: item.meta.icon,
      }))
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      toggleTheme: 'ui/setDarkTheme',
    }),
    openMyCompany() {
      if (!this.userOriginalCompany) {
        return
      }
      
      const originalCompanyId = this.userOriginalCompany.companyId
      if (window.location.pathname === `/company/${originalCompanyId}/properties`) {
        return
      }
      //user is already in company context
      if (originalCompanyId === this.userCompany) {
        this.$router.push('/company/' + originalCompanyId)
      } else {
        // change context and relogin to correct company
        let currentPath = window.location.origin
        window.location.href =
          COMMON_AUTH_PAGE_URL +
          btoa(currentPath + '/company/' + originalCompanyId) +
          '&token=' +
          this.$store.state.auth.token +
          '&companyId=' +
          originalCompanyId
      }
    },
  },
}
</script>

<style scoped>
.top-navigation >>> .v-toolbar__content {
  padding: 0px 16px !important;
}
</style>
