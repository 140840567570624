import { BaseCollection } from '../BaseCollection'
import { Endpoint } from './Endpoint'
import adminApiClient from '../../api/admin-api-client'
import { EventBus } from '../../util/EventBus'

/**
 * Class EndpointCollection
 *
 * @extends BaseCollection
 */

export class EndpointCollection extends BaseCollection {
  constructor(props) {
    super()
    this.companyId = props.companyId || 0

    this.collectionKey = 'endpointId'
  }

  /**
   * @override
   *
   * GET /api/Endpoints/query
   *
   * Get list of endpoints for company
   *
   *
   * @returns {Endpoint[]}
   */
  async fetch() {
    if (!this.companyId) {
      throw new Error('Company Id not set!')
    }
    this.isLoading = true
    try {
      const endpointResponse = await adminApiClient.getEndpoints({
        companyId: this.companyId,
        Page: this.page,
        PageSize: this.pageSize,
        OrderBy: this.orderBy,
        Filter: this.filter,
      })

      this.count = endpointResponse.data.count
      this.items = endpointResponse.data.data.map((ep) => new Endpoint(ep))
      this.isLoading = false
      if (this.filter) {
        EventBus.$emit('restoreFocusEvent')
      }
      return this.items
    } catch (ex) {
      this.isLoading = false
      throw new Error(ex.message)
    }
  }

  /**
   * @override
   *
   * POST /api/Endpoints
   * @param {Endpoint} endpoint
   *
   * @returns {Promise<boolean>}
   */
  create(endpoint) {
    endpoint.isLoading = true
    return adminApiClient
      .createEndpoint({ data: endpoint })
      .then((response) => {
        if (response.status === 201) {
          endpoint.endpointId = response.data
          this.items.push(endpoint)
          endpoint.isLoading = false
          this.added = endpoint
          return endpoint
        } else {
          throw new Error('Failed to create new endpoint')
        }
      })
      .catch((ex) => {
        endpoint.isLoading = false
        throw ex
      })
  }

  /**
   * @override
   * DELETE /api/users
   *
   * @param {number} endpointId
   * @returns
   */
  delete(endpointId) {
    let endpoint = this.items.find((e) => e.endpointId === endpointId)
    endpoint.isLoading = true
    return adminApiClient
      .deleteEndpoint(endpointId)
      .then((response) => {
        endpoint.isLoading = false
        if (response.status === 204) {
          let stillInArray = this.items.findIndex((e) => e.endpointId === endpointId)
          if (stillInArray > -1) {
            this.items.splice(stillInArray, 1)
          }
          this.removed = endpoint
          return true
        }
        return false
      })
      .catch((error) => {
        endpoint.isLoading = false
        throw error
      })
  }

  async fetchById(id) {
    this.isLoading = true
    try {
      const response = await adminApiClient.getEndpoint(id)

      // Check if response.data is an array and has multiple items
      if (Array.isArray(response.data) && response.data.length > 1) {
        throw new Error(`More than 1 found with id: ${id}`)
      }

      // Handle the data whether it's an array with one item or a single object
      const endpointData = Array.isArray(response.data) ? response.data[0] : response.data
      const endpoint = new Endpoint(endpointData)

      this.count = 1
      return endpoint
    } finally {
      this.isLoading = false
    }
  }
}
