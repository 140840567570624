<template>
  <v-tooltip 
    v-if="$accesscontrol().isGlobalAdmin"
    top
  >
    <template #activator="{ on, attrs }">
      <v-btn
        depressed            
        v-bind="attrs"
        min-width="20"
        class="mx-1"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon
          small          
        >
          {{ showImplicit ? "fas fa-eye-slash" : "fas fa-user-alien" }}
        </v-icon>
      </v-btn>
    </template>
    <span> {{ showImplicit ? $t('HideImplicitUsers') : $t('ShowImplicitUsers') }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'HdImplicitToggleButton',
  props: {
    showImplicit: {
      type: Boolean,
      required: true
    }
  },
}
</script>