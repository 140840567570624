<template>
  <div>
    <v-overlay
      :value="expanded"
      style="z-index: 100;"
      @click.native="onClickOutside"
    >
    </v-overlay>
    
    <v-navigation-drawer 
      v-model="expanded"
      fixed
      right
      persistant
      :permanent="expanded"
      width="35vw"
      :class="expanded ? 'sliding-panel expanded' : 'sliding-panel'"
      style="z-index: 101;"
      disable-resize-watcher
    >
      <div class="sliding-panel-header">
        <slot name="header">
        </slot>
      </div>

      <div class="sliding-panel-content">
        <slot
          ref="default-slot"
          name="default"
        >
        </slot>
      </div>

      <div class="sliding-panel-footer">
        <slot
          name="footer"          
        >
        </slot>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    canClose: {
      type: Boolean,
      default() {
        return true
      }
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    open() {
      this.expanded = true
      document.scrollingElement.style.overflow = 'hidden'
    },
    close() {
      document.scrollingElement.style.overflow = 'visible'
      this.expanded = false
    },
    onClickOutside() {
      this.$emit('slidingPanelClickOutside')
    },
    
  },
}
</script>

<style>
.sliding-panel > .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sliding-panel-header {
  display: flex;
  padding: 10px;
}

.sliding-panel-content {
  overflow-y: auto;
  padding-bottom: 10px;
}

.sliding-panel-footer {
  position: relative;
  display: flex;
  bottom: 0;
  padding: 10px;
  width: 100%;
  max-height: 70px;
}


</style>