<template>
  <v-container 
    fluid
    class="px-6"
    style="max-width: 1400px;"
  >
    <slot></slot>
  </v-container>
</template>

<script>
export default {
    name: 'HdContainer'
}
</script>
