/**
 * Class BaseCollection
 *
 * This class should never be used directly
 *
 */
export class BaseCollection {
  constructor(props) {
    this.isLoading = false

    /**
     * @property {object[]} data
     */
    this.items = []

    /**
     * total amount of items (can be different from items.length when paging is used)
     * @property {number} count
     */
    this.count = 0

    /**
     * Current page
     *
     * @property {number} page
     */
    this.page = props ? props.page || 1 : 1

    /**
     * items per page
     *
     * @property {number} pageSize
     */
    this.pageSize = props ? props.pageSize || 50 : 50

    /**
     * how many times collection has loaded records from api
     *
     * @property {number} loadCount
     */
    this.loadCount = 0

    /**
     * columns used in grids
     *
     * @property {array} columns
     */
    this.columns = []

    /**
     * sorting in remote queries
     *
     * @property {string} orderBy
     */
    this.orderBy = null

    /**
     * filter string used in remote queries
     * https://github.com/alirezanet/Gridify#supported-filtering-operators
     *
     * @property {string} filter
     */
    this.filter = null

    /**
     * collection key
     *
     * @property {string} collectionKey unique key of items in collection
     *
     */
    this.collectionKey = null

    /**
     * @property {object} added object which added last by create method
     */
    this.added = null

    /**
     * @property {object} removed object which removed last by delete method
     */
    this.removed = null

    /**
     * @property {object} updated object which updated last by update method
     */
    this.updated = null
    /**
     * AbortController to be used with .abort() on collection and passed to any request using it
     */
    this.abortController = new AbortController()
  }

  /**
   * @returns {*} the only item in the items array
   */
  get only() {
    return this.items.length === 1 ? this.items[0] : undefined
  }
  /**
   * Get a list of records from API
   * This method should be overridden in sub classes
   * https://github.com/alirezanet/Gridify#supported-filtering-operators
   */
  fetch() {
    throw new Error('Method fetch not implemented!')
  }

  /**
   * Create a new record to API
   * This method should be overridden in sub classes
   */
  create() {
    throw new Error('Method create not implemented!')
  }

  /**
   * Delete a new record to API
   * This method should be overridden in sub classes
   */
  delete() {
    throw new Error('Method delete not implemented!')
  }
  /**
   * @param {object} record for User, Endpoint, Subdvision
   */
  async update(record) {
    try {
      this.isLoading = true
      await record.update()

      this.updateItem(record, this.collectionKey)
      this.updated = record
      this.isLoading = false
      return this.updated
    } catch (ex) {
      this.isLoading = false
      throw ex
    }
  }

  /**
   * Fetch one record from api
   * This method should be overridden in sub classes
   * @param id
   */
  fetchById() {
    throw new Error('Method fetchById not implemented!')
  }

  /**
   * Update item data in the items array
   *
   * This needs to be called when you want to replace an item with a new one.
   * for example when getting updated record from api
   *
   * @param {*} item
   * @param {string} idProp id property. for example userDbId
   */
  updateItem(item, idProp) {
    let oldItem = this.items.find((i) => i[idProp] === item[idProp])
    if (!oldItem) {
      throw new Error('Id ' + item[idProp] + ' not found.')
    }
    /**
     * We have to copy values from new (updated) item to old one.
     *
     * This is because the old item is reactive and new one isn't (VUE specific).
     */
    Object.keys(oldItem).forEach((k) => {
      oldItem[k] = item[k]
    })
  }

  getRecordByCollectionKey(id) {
    return this.items.find((r) => r[this.collectionKey] === id)
  }

  /***
   * Used together with devExpress custom store
   *
   */
  setRequestOpts(opts) {
    this.pageSize = opts.take ?? 9999
    if (opts.skip) {
      this.page = opts.skip / this.pageSize + 1
    } else {
      this.page = 1
    }
    if (opts.sort?.[0]) {
      const { selector, desc } = opts.sort[0]
      this.orderBy = `${selector} ${desc ? 'desc' : 'asc'}`
    } else {
      this.orderBy = null
    }
    this.filter = opts.filter ? this._getFilterString(opts.filter) : null
  }

  abortRequests() {
    this.abortController.abort()
  }

  /**
   * @private
   *
   * Generates a filter string used in backend queries
   *
   * @param {array} filterArray from devExpressGrid
   * @returns {string}
   */
  _getFilterString(filterArray) {
    this.devExtremeStoreFilterArray = filterArray
    let filter = this._getFilterRecursive(filterArray).join('')
    return filter
  }

  /**
   * @private
   *
   * Generates a filter string used in backend queries
   *
   * @param {array} filterArray from devExpressGrid
   * @param {array} filterString current filterString, empty at start-
   * @param {array} invertNext invert all from this array depth.
   * @returns {string}
   *   */
  _getFilterRecursive(filterArray, filterString = '', invertNext = false) {
    return filterArray.map((filter, index, arr) => {
      if (filter === '!') {
        invertNext = true
        return filterString
      } else if (Array.isArray(filter) && filter.some((f) => Array.isArray(f))) {
        return '(' + this._getFilterRecursive(filter, filterString, invertNext).join('') + ')'
      } else if (Array.isArray(filter)) {
        let [columnName, method, value] = filter
        // /i for case-insensitive. Single filters do not fall to this 'if' branch
        return (
          columnName +
          this._mapFilter(method, invertNext) +
          value +
          (typeof value === 'string' && value.trim() !== '' ? '/i' : '')
        )
      } else if (typeof filter === 'string') {
        //A single filter is 3 items in the array. Add case-insensitive flag to the last one that is the value
        const mappedVal = this._mapFilter(filter, invertNext)
        return arr.length === 3 &&
          arr.every((f) => typeof f === 'string') &&
          index === 2 &&
          arr.filterValue &&
          arr.filterValue.trim() !== ''
          ? mappedVal + '/i'
          : mappedVal
      } else {
        return filter
      }
    })
  }

  /**
   * @private
   *
   * filter character mapper from devexpress format
   * to gridify format
   *
   * @param {string} method
   * @returns {string}
   */
  _mapFilter(method, invertEqual = false) {
    const mapper = {
      contains: '=*',
      and: ',',
      or: '|',
      '<>': '!=',
      notcontains: '!*',
      startswith: '^',
      endswith: '$',
    }

    if (invertEqual) {
      switch (method.toLowerCase()) {
        case '=':
          method = '!='
          break
        case '!=':
          method = '='
          break
        case 'and':
          method = 'or'
          break
        case 'or':
          method = 'and'
          break
        default:
          break
      }
    }

    let mapped = mapper[method.toLowerCase()] || method
    return mapped
  }
}
