<template>
  <v-select
    ref="groupselect"
    :label="$t('Group')"
    :items="groups"
    :item-text="(item) => $t(item.groupName)"
    item-value="groupId"
    :value="selectedGroup"
    :readonly="readonly"
    :disabled="readonly"
  >
    <template #selection="{ item }">
      <v-icon
        small
        class="mr-2"
      >
        {{ icon(item.groupId) }}
      </v-icon>
      {{ $t(item.groupName) }}
    </template>
    <template #item="{ item }">
      <v-icon
        small
        class="mr-2"
      >
        {{ icon(item.groupId) }}
      </v-icon>
      {{ $t(item.groupName) }}
    </template>
  </v-select>
</template>
<script>

import { userGroupIcon } from '../../../util/tools'
export default {
  name: 'HdGroupSelector',
  props: {
    selectedGroup: {
      type: Number,
      required: false,
      default() {
        return 0
      }
    },
    readonly: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
 
  computed: {
    groups() { 
      return [...this.$store.state.user.groups].sort((a, b) => a.groupValue - b.groupValue)
    }
  },

  methods: {
    icon(gid) {
      return userGroupIcon(gid)
    }, 
    /**
     * Returns selection value
     */
    getValue() {       
      return this.$refs.groupselect.internalValue
    },
  }
}
</script>
